import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Filename from '../common/filename';

const SEND_TUITION_TEXT_MSG = gql`
query TuitionTextMsg($daysBefore:String!)
  { tuitionTextMsg(daysBefore:$daysBefore){
    id
    studentId
    studentName
    amount
    dueDate
    smsPhone1
    smsPhone2
  }
}
`;

const TuitionMsg = (props) => {
  const { loading, error, data } = useQuery(
    SEND_TUITION_TEXT_MSG, {
    variables: { daysBefore: "2" }
  });
  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;

  return (
    <>
      <br />
      <br />
      {Filename('tuitionTxtMsg.jsx')}
      <div className="container text-center">
        <div className='row'>
          <h4 className='col-12 mb-5'>교육비 문자 만들기</h4>
        </div>
        <div className="card m-2" style={{ "backgroundColor": "#96d5ff" }}>
          <div className="row text-center" style={{ fontSize: "12px" }}>
          </div>
          {data.tuitionTextMsg.map(t => {
            // const findlink = document.getElementsByTagName("link");
            // findlink[0].href = "sms:" + t.smsPhone1;
            // onClick={() => <a href=" " >문자 보내기</a>}
            const content = "[그랜드음악학원] 안녕하세요. " +
              t.studentName + " 이달 교육비는 " +
              t.amount + "원 입니다. " + (t.dueDate.slice(8, 9) === "0" ? t.dueDate.slice(9, 10) : t.dueDate.slice(8, 10)) + "일까지 납부해 주시기 바랍니다. 감사합니다.^^"
            return (
              <div className="row text-center card-body m-2" style={{ fontSize: "16px", "backgroundColor": "#f7fffc", "border": "3px dashed red" }} >
                [그랜드음악학원] 안녕하세요.
                {t.studentName} 이달 교육비는
                {t.amount}원 입니다. {t.dueDate.slice(8, 9) === "0" ? t.dueDate.slice(9, 10) : t.dueDate.slice(8, 10)}일까지 납부해 주시기 바랍니다. 감사합니다.^^
                ==&gt; <button onClick={() => {
                  window.location = 'sms:' + t.smsPhone1 + "?" + encodeURIComponent(content)
                }} >{t.smsPhone1}</button>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}

export default TuitionMsg