import React from "react";
import Filename from '../common/filename';
import StudentsList1 from './studentsList'
import { gql, useQuery } from '@apollo/client';

const STUDENTS_LIST = gql`
query StudentsList{
  classify{
    lessoning {
      name
      status
      onTime
    }
  }
}
`;
const ShowBan = ({ ban }) => {
  const { loading, error } = useQuery(STUDENTS_LIST);
  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;

  return (
    <>
      {Filename('common/students.jsx')}
      <StudentsList1 ban={ban} />
    </>
  );
}

export default ShowBan