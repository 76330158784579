import React from "react";
import { CustomGesture, Swipeable } from "react-touch";
import { 복구제스쳐 } from "./class_badge";
import { HandlerConsumer } from "../new/handler";
import StudBtn from "./studBtn";

const NameBtn = ({
  student,
  onoff,
  todayDay,
  day,
  showPic,
  showAjNum,
  showTel,
  students,
  time,
  showNameOnly,
  green
}) => {
  return (
    <HandlerConsumer>
      {handler => {
        const { onNameClick, onShowButtons, onCancelAction } = handler;
        return (
          <CustomGesture
            config={복구제스쳐}
            onGesture={() => {
              onCancelAction({ student, onoff });
            }}
          >
            <Swipeable
              onSwipeLeft={() => {
                onShowButtons({
                  student,
                  onoff,
                  time,
                  dow: day
                });
              }}
              onSwipeRight={() => {
                onShowButtons({
                  student,
                  onoff,
                  time,
                  dow: day
                });
              }}
            >
              {StudBtn({
                student,
                onoff,
                todayDay,
                day,
                onNameClick,
                showPic,
                showAjNum,
                showTel,
                students,
                time,
                showNameOnly,
                green
              })}
            </Swipeable>
          </CustomGesture>
        );
      }}
    </HandlerConsumer>
  );
};

export default NameBtn;
