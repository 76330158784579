import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Loading from '../../common/loading'
import { btnStyle1 } from "../../common/setting";
import { 책진도, 정렬 } from "../../common/jindoFunc";
import { MusicLib } from "../music/selectMusic";
import {
  getStudentConcert1 as getStudentConcert,
  saveStudent,
  saveDocusAsExcel,
  readExcelAsDocus
} from "../../../services/studentService";
import { paginate } from "../../../utils/paginate";
import _ from "lodash";
import authService from "../../../services/authService";
export class Docu1 extends Component {
  // sortColumn: { path: "name", order: "asc" },
  constructor(props) {
    super(props)
    if (new.target === Docu1) {
      throw new TypeError("Cannot construct Abstract instances directly");
    }
    this.state = {
      currentPage: 1,
      pageSize: 200,
      students: [],
      searchQuery: "",
      sortColumn: {},
      ban: "",
      win1open: false,
      sortColumnMusic: { path: "melodizer", order: "asc" },
      selectedMusic: null,
      studentId: null,
      cb: null,
      small: false,
      fontSize: 20
    };
  }
  fontInput = null
  className = 'page-ct'
  style = {}
  title = <div id='title-ct'>
    <p id="subject-ct">정기연주회 (콩쿨) 명단</p>
    <p id="date-ct">( 2020년 2월 15일 )</p>
  </div>
  fileName = 'concert1/docu.jsx'
  DocusTable = () => { }
  user = authService.getCurrentUser();
  async componentDidMount() {
    const { data: students1 } = await getStudentConcert();
    let students = students1;
    students = students.filter(s => s.concert !== undefined)

    // students.concert.jindo/num 더이상 사용하지 않는다. db에서 삭제할것. 
    // 현재 진도대로 여기에서 만들어서 거기에 있는것처럼 사용함.
    let students2 = []
    정렬(students, { title: '체르니50' }, students2)
    정렬(students, { title: '체르니40' }, students2)
    정렬(students, { title: '체르니30' }, students2)
    정렬(students, { title: '체르니100' }, students2)
    정렬(students, { title: '바이엘4' }, students2)
    정렬(students, { title: '바이엘3' }, students2)
    let nn = -10
    students2.map(s => {
      nn = nn + 10
      s.concert.num = nn
      s.concert.jindo = 책진도(s.pJin)
      return null
    })
    //** 
    let ss = students1.filter(s => s.concert !== undefined && (s.concert.instrument === '바이올린' || s.concert.instrument === '풀룻'))
    ss.map(s => {
      const s1 = { ...s }
      nn = nn + 10
      s1.concert.num = nn
      s1.v_f = true
      students2.push(s1)
      return null
    })
    this.setState({ students: students2 });
  }
  onCloseModal = win => {
    if (win === "win1") this.setState({ win1open: false });
  };
  handleWinOpen = (item, cb) => {
    this.setState({ win1open: true, studentId: item._id, cb });
  };
  handleSelectBan = ban => {
    this.setState({ ban });
  };
  handleChange = e => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value
    });
  };
  handleChanged = (id, path, value) => {
    const students = [...this.state.students];
    const ids = students.map(b => b._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    students[index][path] = value;
    if (path === 'melodizer' && this.state.selectedMusic !== undefined)
      this.setState({ selectedMusic: null, studentId: null, students, lastEdit: id });
    else
      this.setState({ students, lastEdit: id });
  };
  toDB = (m) => {
    const t = "[object Object]" //don't know what. have just deleted
    delete m[t]
    delete m.edit;
    const v_f = m.v_f
    if (m.v_f === undefined || (m.v_f && m.melodizer === "")) {
      delete m.v_f;
      saveStudent(m);
    }
    m.v_f = v_f
  }
  handleEditMode = (item, path, value) => {
    let students = [...this.state.students];
    const index = _.findIndex(this.state.students, item);
    students.map(m => {
      if (m._id === this.state.lastEdit) {
        this.toDB(m)
      }
      if (m.edit) {
        this.toDB(students[index])
        delete m.edit;
      }
      return null
    });
    students[index].edit = true;
    this.setState({ students });
  };
  handleChanged1 = (id, value) => {
    const students = [...this.state.students];
    const ids = students.map(b => b._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    students[index]["musicBook"] = value.musicBook;
    students[index]["musicTitle"] = value.musicTitle;
    students[index]["melodizer"] = value.melodizer;
    students[index]["musicBook_s"] = value.musicBook_s;
    students[index]["musicTitle_s"] = value.musicTitle_s;
    students[index]["melodizer_s"] = value.melodizer_s;
    this.toDB(students[index])
    if (this.state.selectedMusic !== undefined)
      this.setState({ selectedMusic: null, studentId: null, students });
  };
  handleChangeColor = (id, field) => {
    if (field !== 'name' && field !== 'concr') return null
    const students = [...this.state.students];
    const ids = students.map(b => b._id);
    const index = ids.indexOf(id);
    students[index] = { ...students[index] };
    const path = field === 'name' ? 'nameColor' : field === 'concr' ? 'ccColor' : ''
    if (students[index]['concert'][path] === 'rgb(255, 255, 255)')
      students[index]['concert'][path] = 'rgb(255, 255, 153)'
    else
      students[index]['concert'][path] = 'rgb(255, 255, 255)'
    this.toDB(students[index])
    this.setState({ students });
  };
  handleLike = book => {
    const students = [...this.state.students];
    const index = students.indexOf(book);
    students[index] = { ...students[index] };
    students[index].liked = !students[index].liked;
    this.setState({ students });
  };
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      students: allstudents
    } = this.state;
    let filtered = allstudents;
    if (searchQuery)
      filtered = allstudents.filter(
        m =>
          m.name !== null &&
          m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const students = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: students };
  };
  handleMusicSelect = (music) => {
    this.setState({ selectedMusic: music, win1open: false });
  };
  saveExcel = async () => {
    await saveDocusAsExcel();
  };
  readExcel = async () => {
    await readExcelAsDocus();
  };
  render() {
    const { sortColumn, sortColumnMusic } = this.state;
    const { data: students } = this.getPagedData();
    if (students.length === 0) {
      return <Loading></Loading>
    }
    else
      return (
        <>
          {/* <pre>{JSON.stringify(this.title, null, 2)}</pre> */}
          <Modal
            open={this.state.win1open}
            onClose={() => this.onCloseModal("win1")}
            center
            closeOnEsc
            closeOnOverlayClick
            styles={{
              modal: {
                position: "absolute",
                marginTop: 50,
              },
              closeIcon: {
                position: "fixed",
                top: 110,
                left: 80,
              },
            }}
          >
            <MusicLib
              user={this.props.user}
              sortColumn={sortColumnMusic}
              onMusicSelect={this.handleMusicSelect}
            ></MusicLib>
          </Modal>
          {this.props.user && this.props.user.isAdmin && (
            <div className="noPrint"><code>{this.fileName}</code></div>
          )}
          {
            this.user && this.user.isAdmin && (
              <>
                <div className="noPrint">
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() => this.saveExcel()}
                  >
                    엑셀저장
              </button>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() => this.readExcel()}
                  >
                    엑셀읽기
              </button>
                  {this.fontInput}
                </div>
              </>
            )
          }
          <div className="noPrint">
            {/* //todo: Change to <Ibtn/> */}
            <i class="fas fa-chalkboard-teacher fa-lg" onClick={() => {
              this.props.history.push("/Chuls5");
            }} style={btnStyle1}
            ></i>
            {!this.state.small && (

              < i className="fas fa-praying-hands"
                onClick={() => {
                  this.setState({ small: !this.state.small });
                }}
                style={btnStyle1}>
              </i>)}
            {this.state.small && (

              < i class="fas fa-hands"
                onClick={() => {
                  this.setState({ small: !this.state.small });
                }}
                style={btnStyle1}>
              </i>)
            }
          </div>
          <div className="noPrint">
            <h6>
              {`* 작곡자/곡명을 입력해주세요.^^ 눌러서 칸이 나타나면 입력하고 다른
            학생 칸을 클릭하면 자동으로 저장됩니다. 다른 학생칸을 누르지 않으면
            입력내용이 저장되지 않습니다.`}
            </h6>
          </div>
          <section className={this.className} style={this.style} >
            {this.title}
            {this.DocusTable({
              small: this.state.small,
              user: this.user,
              students: students
                .filter(m => this.state.ban === "" ? m : m.pianoBan === this.state.ban + "반"),
              sortColumn: sortColumn,
              fontSize: this.state.fontSize,
              onLike: this.handleLike,
              onSave: this.handleSave,
              onSort: this.handleSort,
              onEditMode: (item, path, value) => this.handleEditMode(item, path, value),
              onChangeColor: (id, field) => this.handleChangeColor(id, field),
              onChanged1: (id, value) => this.handleChanged1(id, value),
              onChanged: (id, path, value) => this.handleChanged(id, path, value),
              onWinOpen: this.handleWinOpen,
              selectedMusic: { studentId: this.state.studentId, music: this.state.selectedMusic, clear: () => this.setState({ studentId: '', music: '' }) },
              table: this.table
            })}
          </section>
        </ >
      );
  }
}
