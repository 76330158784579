import React, { Component } from 'react';
//todo: Holdable 사용안하고 있음. 필요없으면 빼시오
import Holdable from './holdable';

const bgColor = ({ onoff, students, day, time }) => {
  let color;
  const inColor = 'rgba(247, 199, 255, 1)'
  const outColor = 'rgba(207, 249, 255, 1)'
  const silColor = 'rgba(229, 235, 113, 1)'

  if (onoff === "in") color = inColor
  else color = outColor

  // 실장이 운전하는 차인지 결정하는 로직
  let placeField = onoff === 'in' ? 'ip' : 'op'
  let timeField = onoff === 'in' ? 'it' : 'ot'
  placeField = placeField + day
  timeField = timeField + day
  students
    .map(s => {
      if (s[placeField] && s[timeField] && s[timeField] === time && s[placeField].includes('실장')) {
        color = silColor
        return null
      }
      return null
    })
  return color
}
export default class Car extends Component {
  onClick = () => { }
  onHold = () => {
    this.setState({ color: this.state.color === "light" ? "warning" : "light" })
  }
  render() {
    const { time, onoff, count, students, day } = this.props
    return (
      <Holdable
        onClick={this.onClick}
        onHold={this.onHold}
      >
        <div
          className={
            onoff === "in"
              ? "badge badge-success"
              : "badge badge-secondary"
          }
          style={{
            color: 'rgb(0,0,0)',
            backgroundColor: bgColor({ onoff, students, day, time }),
            border: '1px dashed red',
            marginBottom: "-10px",
            marginTop: "10px"
          }}

        >
          <span className={`badge badge-light mr-1`}>
            {onoff === "in" ? "등" : "하"}
          </span>
          {time}
          <span className="badge badge-light m-1">
            {count}
          </span>
        </div>
      </Holdable >
    )
  }
}