import React from "react";
import Filename from "./filename";

// 레슨 화면과 탑승정보 화면에서 동시에 사용중.
const Day = ({ scope, onSetDay }) => {
  const a = scope;
  const day = a.state.day ? a.state.day : a.state.dow;
  const { user } = scope;

  if (user && user.isPrincipal) {
    return (
      <>
        {Filename("common/day.jsx")}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginTop: "2rem",
          }}
        >
          {/* Green Checkbox */}
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <input
              type="checkbox"
              checked={scope.state.green || false} // 체크 상태
              onChange={() =>
                scope.setState((prevState) => ({ green: !prevState.green }))
              } // 상태 토글
              style={{
                appearance: "none", // 기본 브라우저 스타일 제거
                WebkitAppearance: "none", // Safari 브라우저 호환성
                width: "2rem",
                height: "2rem",
                cursor: "pointer",
                backgroundColor: scope.state.green ? "green" : "white", // 선택 상태에 따라 배경색 변경
                border: "3px solid #ccc", // 테두리
                borderRadius: "4px", // 둥근 모서리
                verticalAlign: "middle", // 세로 정렬 조정
                marginTop: "0.2rem", // input을 약간 아래로 이동
              }}
            />
          </label>

          {/* Day Buttons */}
          <div
            style={{
              display: "flex",
              alignItems: "center", // 버튼 내부의 세로 정렬도 중앙으로 설정
              gap: "0.5rem",
              marginTop: "-0.4rem", // 버튼을 약간 위로 이동
            }}
          >
          {[1, 2, 3, 4, 5].map((n) => (
            <Button
              day={day}
              n={n}
              key={Math.floor(Math.random() * 100000)}
              onSetDay={onSetDay}
              scope={a}
            />
          ))}
          </div>
        </div>
      </>
    );
  } else return null;
};

const handleClick = (e) => (onSetDay, scope) => {
  onSetDay(e, scope);
};

const Button = ({ n, day, onSetDay, scope }) => {
  const a = ["월", "화", "수", "목", "금"];
  let badge = "badge badge-pill m-1 badge-";

  return (
    <button
      type="button"
      className={day === parseInt(n) ? badge + "warning" : badge + "light"}
      style={day === parseInt(n) ? { backgroundColor: "#f7dc6f" } : {}}
      onClick={(e) => handleClick(e)(onSetDay, scope)}
      value={n}
    >
      {a[n - 1]}
    </button>
  );
};

export default Day;