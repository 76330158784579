import React from "react";
import { useTheme } from "../../context/ThemeContext"; // ThemeContext 가져오기
import { ShowBan, Counter } from "../common/index1";
import { Board } from "./index1";

const Monitor = ({ board, ban, user, counter, board1 }) => {
  const { theme } = useTheme(); // 테마 가져오기

  return (
    <div
      style={{
        backgroundColor: theme.background, // 테마 배경색
        color: theme.text, // 테마 텍스트 색상
      }}
    >
      <Board board={board} board1={board1} />
      {/* <BanName /> */}
      <ShowBan ban={ban} />
      <Counter user={user} counter={counter} />
    </div>
  );
};

export default Monitor;
