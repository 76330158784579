import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { useTheme } from '../../context/ThemeContext';

const GlobalStyleComponent = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    transition: all 0.3s ease;
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.secondary};
    }
  }

  .card {
    background-color: ${({ theme }) => theme.card};
    border-color: ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.text};
  }

  .table {
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.card};
  }

  .table thead th {
    border-bottom-color: ${({ theme }) => theme.border};
  }

  .table td {
    border-top-color: ${({ theme }) => theme.border};
  }

  .alert-success {
    background-color: ${({ theme }) => theme.alert.success};
  }

  .alert-warning {
    background-color: ${({ theme }) => theme.alert.warning};
  }

  .alert-danger {
    background-color: ${({ theme }) => theme.alert.danger};
  }

  .alert-info {
    background-color: ${({ theme }) => theme.alert.info};
  }

  .navbar {
    background-color: ${({ theme }) => theme.card} !important;
    border-bottom: 1px solid ${({ theme }) => theme.border};
  }

  .navbar-light {
    background-color: ${({ theme }) => theme.card} !important;
  }

  .navbar-brand {
    color: ${({ theme }) => theme.text} !important;
    font-weight: bold !important;
    background-color: ${({ theme }) => theme.card} !important;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }

  .navbar-brand:hover {
    color: ${({ theme }) => theme.primary} !important;
    background-color: ${({ theme }) => theme.hover} !important;
  }

  .nav-link {
    color: ${({ theme }) => theme.text} !important;
    text-align: left !important;
  }

  .nav-item {
    text-align: left !important;
  }

  .nav-item .nav-link {
    color: ${({ theme }) => theme.text} !important;
    text-align: left !important;
  }

  .nav-item .nav-link:hover {
    color: ${({ theme }) => theme.primary} !important;
  }

  .nav-item .nav-link.active {
    color: ${({ theme }) => theme.primary} !important;
  }

  .navbar-toggler {
    border-color: ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.card};
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='${({ theme }) => theme.text.replace('#', '%23')}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-nav {
    background-color: ${({ theme }) => theme.card};
    margin-right: auto !important;
    text-align: left !important;
  }

  .navbar-collapse {
    background-color: ${({ theme }) => theme.card};
  }
`;

export const GlobalStyle = () => {
    const { theme } = useTheme();
    return <GlobalStyleComponent theme={theme} />;
};