import React from "react";
import { renderCell } from "../../common/func7";
import { EditTd } from './editTd'
const returnTd = (field, column, selectedMusic, onChanged1, item, fontSize) => {
  if (column.path === field &&
    selectedMusic.studentId === item._id &&
    selectedMusic.music !== null) {
    onChanged1(item._id, selectedMusic.music);
    if (field === 'musicBook') selectedMusic.clear();
    // if (field === 'musicBook') selectedMusic.clear();
    return (
      <p
        style={{ fontSize: parseInt(fontSize) }}
      >
        {selectedMusic.music !== undefined ? selectedMusic.music[field] : null}
      </p>
    );
  }
}
export const Td = ({ item, column, fontSize, handleClick, onChanged, onChanged1, onChangeColor, onWinOpen, selectedMusic }) => {
  if (column.path === 'button') {
    if (item.v_f) return (
      <p className='music-search-button'>
      </p>
    );
    return (
      <p className='music-search-button'>
        {/* //todo: Change to <Ibtn/> */}
        <i style={{ backgroundColor: 'rgba(255,255,255,0)' }} onClick={() => onWinOpen(item, (selectedMusic) => {
          onChanged(item._id, 'musicBook', selectedMusic.musicBook);
          onChanged(item._id, 'musicTitle', selectedMusic.musicTitle);
          onChanged(item._id, 'melodizer', selectedMusic.melodizer);
        })} className="fas fa-search"></i>
      </p>
    );
  }
  if (column.path === "name") {
    return (
      <p className='name'
        style={{ fontSize: parseInt(fontSize), backgroundColor: item.concert.nameColor }}
        onClick={() => onChangeColor(item._id, 'name')}
      >
        {renderCell(item, column)}
      </p>
    );
  }
  if (column.path === "jindo") {
    if (item.v_f) return (
      <p className='jindo'
        style={{ fontSize: parseInt(fontSize) }}
      >
        {item.concert.instrument}
      </p>
    );
    return (
      <p className='jindo'
        style={{ fontSize: parseInt(fontSize) }}
      >
        {item.concert.jindo}
      </p>
    );
  }
  if (column.path === "hag") {
    return (
      <p className='hag'
        style={{ fontSize: parseInt(fontSize) }}
      >
        {item.concert.hag}
      </p>
    );
  }
  if (column.path === "concr") {// path:콩쿨
    if (item.v_f) return (
      <p className='concr' />
    );
    return <p className='concr'
      style={{ fontSize: parseInt(fontSize), backgroundColor: item.concert.ccColor }}
      onClick={() => onChangeColor(item._id, 'concr')}
    />
  }
  if (column.path === "musicBook") {
    if (item.edit) {
      return (
        <p className='musicBook'>
          <EditTd
            path={column.path}
            itemId={item._id}
            item={renderCell(item, column)}
            onChanged={onChanged}
            className="form-control my-input"
          />
        </p>
      );
    }
    else {
      if (column.path === undefined) return null;
      returnTd('musicBook', column, selectedMusic, onChanged1, item, fontSize)//교재
      // returnTd('musicBook')//교재
      return (
        <p className='musicBook'
          style={{ fontSize: parseInt(fontSize) }}
          onClick={() => handleClick(item, column.path)}
        >
          {renderCell(item, column)}
        </p>
      );
    }
  }
  if (column.path === "musicBook_s") {
    if (item.edit) {
      return (
        <p className='musicBook'>
          <EditTd
            path={column.path}
            itemId={item._id}
            item={renderCell(item, column)}
            onChanged={onChanged}
            className="form-control my-input"
          />
        </p>
      );
    }
    else {
      if (column.path === undefined) return null;
      returnTd('musicBook_s', column, selectedMusic, onChanged1, item, fontSize)//교재
      // returnTd('musicBook')//교재
      return (
        <p className='musicBook'
          style={{ fontSize: parseInt(fontSize) }}
          onClick={() => handleClick(item, column.path)}
        >
          {renderCell(item, column)}
        </p>
      );
    }
  }
  if (column.path === "musicTitle_s") {
    if (item.edit) {
      return (
        <p className='musicTitle'>
          <EditTd
            path={column.path}
            itemId={item._id}
            item={renderCell(item, column)}
            onChanged={onChanged}
            className="form-control my-input"
          />
        </p>
      );
    }
    else {
      if (column.path === undefined) return null;
      returnTd('musicTitle_s', column, selectedMusic, onChanged1, item, fontSize)//곡명
      // returnTd('musicTitle')//곡명
      return (
        <p className='musicTitle'
          style={{ fontSize: parseInt(fontSize) }}
          onClick={() => handleClick(item, column.path)}
        >
          {renderCell(item, column)}
        </p>
      );
    }
  }
  if (column.path === "musicTitle") {
    if (item.edit) {
      return (
        <p className='musicTitle'>
          <EditTd
            path={column.path}
            itemId={item._id}
            item={renderCell(item, column)}
            onChanged={onChanged}
            className="form-control my-input"
          />
        </p>
      );
    }
    else {
      if (column.path === undefined) return null;
      returnTd('musicTitle', column, selectedMusic, onChanged1, item, fontSize)//곡명
      // returnTd('musicTitle')//곡명
      return (
        <p className='musicTitle'
          style={{ fontSize: parseInt(fontSize) }}
          onClick={() => handleClick(item, column.path)}
        >
          {renderCell(item, column)}
        </p>
      );
    }
  }
  if (column.path === "melodizer_s") {
    if (item.edit) {
      return (
        <p className='melodizer'>
          <EditTd
            path={column.path}
            itemId={item._id}
            item={renderCell(item, column)}
            onChanged={onChanged}
            className="form-control my-input"
          />
        </p>
      );
    }
    else {
      if (column.path === undefined) return null;
      returnTd('melodizer_s', column, selectedMusic, onChanged1, item, fontSize)//작곡자
      // returnTd('melodizer')//작곡자
      return (
        <p className='melodizer'
          style={{ fontSize: parseInt(fontSize) }}
          onClick={() => handleClick(item, column.path)}
        >
          {renderCell(item, column)}
        </p>
      );
    }
  }
  if (column.path === "melodizer") {
    if (item.edit) {
      return (
        <p className='melodizer'>
          <EditTd
            path={column.path}
            itemId={item._id}
            item={renderCell(item, column)}
            onChanged={onChanged}
            className="form-control my-input"
          />
        </p>
      );
    }
    else {
      if (column.path === undefined) return null;
      returnTd('melodizer', column, selectedMusic, onChanged1, item, fontSize)//작곡자
      // returnTd('melodizer')//작곡자
      return (
        <p className='melodizer'
          style={{ fontSize: parseInt(fontSize) }}
          onClick={() => handleClick(item, column.path)}
        >
          {renderCell(item, column)}
        </p>
      );
    }
  }
  return <p></p>
}
