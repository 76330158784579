import React, { useState, useEffect } from 'react';

const MyImage = ({ imgName }) => {
  const [imageSrc, setImageSrc] = useState(null);

  const fetchImage = async () => {
    if (imageSrc) {
      // 클라이언트 메모리에 이미지가 있으면 바로 사용
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get-image?name=${encodeURIComponent(imgName)+'.jpg'}`);
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob); // Blob을 URL로 변환
      setImageSrc(imageUrl); // React 상태에 저장
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  // useEffect를 사용하여 컴포넌트가 마운트될 때 fetchImage 호출
  useEffect(() => {
    fetchImage();
  }); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행

  return (
    <>
      {imageSrc ? (
        <img src={imageSrc} style={{ width: 30, height: 30, borderRadius: 30 / 2 }} alt={imgName} />
      ) : (
        <></>
      )}
    </>
  );
};

export default MyImage;