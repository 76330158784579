import React from "react";
import { withRouter } from "react-router-dom";
import Chuls7 from "../chuls/chuls7"; // chuls5.jsx 가져오기
import Rides1 from "../bus/rides1"; // rides.jsx 가져오기
import { useTheme } from "../../context/ThemeContext"; // ThemeContext 가져오기

const ChulsAndRides = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // 위아래로 배치
        gap: "0rem", // 두 컴포넌트 사이 간격
        paddingRight: "1rem", // 전체 여백
        backgroundColor: props.theme?.background || "#ffffff", // 테마 배경색
        color: props.theme?.text || "#000000", // 테마 텍스트 색상
        height: "100vh", // 전체 화면 높이
        overflow: "hidden", // 부모 컨테이너에서 스크롤 숨김
      }}
    >
      {/* Rides 컴포넌트 */}
      <div
        style={{
          flex: 1, // 공간을 균등하게 분배
          overflowY: "auto", // 세로 스크롤 활성화
          border: "1px solid #fff", // 테두리 추가
          borderRadius: "8px", // 둥근 모서리
        }}
      >
        <Rides1 {...props} />
      </div>

      {/* Chuls5 컴포넌트 */}
      <div
        style={{
          flex: 1, // 공간을 균등하게 분배
          overflowY: "auto", // 세로 스크롤 활성화
          border: "1px solid #fff", // 테두리 추가
          borderRadius: "8px", // 둥근 모서리
        }}
      >
        <Chuls7 {...props} />
      </div>
      test
    </div>
  );
};

// ThemeContext를 HOC로 전달
const withTheme = (Component) => (props) => {
  const { theme } = useTheme();
  return <Component {...props} theme={theme} />;
};

export default withRouter(withTheme(ChulsAndRides));
