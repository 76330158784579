import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Filename from '../common/filename';

const TEXT_MSG_All = gql`
query TextMsgAll{
    textMsgAll{
    studentName
    studentId
    senderName
    senderId
    content
    receiverPhone
    sentDate
    sentCount
  }
}
`;

const SendText = (props) => {
  const { loading, error, data } = useQuery(TEXT_MSG_All);
  // useEffect(() => {
  //   const subscription = props.socket.emit('sms');
  //   return () => {
  //     // Clean up the subscription
  //     //subscription.unsubscribe();
  //   };
  // });

  const handleSend = (param) => {
    props.socket.emit('sendSMS', param)
  }

  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;

  return (
    <>
      <br />
      <br />
      <br />
      {Filename('sendText135.jsx')}
      <div>
        {data.textMsgAll.map(m => {
          return (
            <div className="m-2 ">
              <div className="card" style={{ "backgroundColor": "#f7fffc" }}>
                <div className="card-body">
                  {/* <h5 className="card-title">Card title</h5>
                      <h6 className="card-subtitle mb-2 text-muted">card subtitle</h6> */}
                  <p className="card-text m-0">송신: {m.senderName} ====&gt; 수신: {m.studentName} </p>
                  <p className="card-text m-0">수신자 전화번호: {m.receiverPhone} </p>
                  <p className="card-text m-0">송신 일시:{m.sentDate}</p>
                  <p className="card-text">송신 회수:{m.sentCount}</p>
                  <p className="card-text mb-0">메세지:{m.content}</p>
                  {/* <a href="#" className="card-link">Card link</a>
                      <a href="#" className="card-link">Another link</a> */}
                  <button className="btn btn-primary p-0 m-0" onClick={() => {
                    handleSend({ phoneNumber: m.receiverPhone, content: m.content })
                  }}>보내기</button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
}
export default SendText