import React, { Component } from "react";
import { createKey } from "./func7";
import _ from "lodash";
// import styles from "../students/studentAllCs.module.css";
// import "../students/studentAllCs.css";
// 20200701 import "../../my.css";

class TableBody extends Component {
  state = { changedItem: {} };

  renderCell = (item, column, esc) => {
    if (column.content) return column.content(item);
    if (esc === "생일달순" && column.path === "dob") {
      return String(_.get(item, column.path)).substring(5, 7);
    } else return _.get(item, column.path);
  };


  handleClick = (item, changedItem) => {
    this.props.onEditMode(item, changedItem);
  };

  handleChanged = changedItem => {
    this.props.onChanged(changedItem);
  };
  //todo: 악기별 학생이름 색구별 안되고 있음. tableBody8.jsx 참고해서 고칠것
  render() {
    const { data, columns, fontSize, onDelete } = this.props;
    return (
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            {columns.map(column => {
              if (!item.edit) {
                if (column.path === undefined) return null;
                else
                  return (
                    <td
                      key={createKey(item, column)}
                      onClick={() => this.handleClick(item, column.path)}
                      style={{ fontSize: parseInt(fontSize) }}
                    >
                      {/* {`item:${JSON.stringify(item)}`}
                {`column:${JSON.stringify(column)}`} */}
                      {this.renderCell(item, column)}
                    </td>
                  );
              }
              if (item.edit) {
                if (column.path === "name" || column.path === "hag1") {
                  return (
                    <td
                      key={createKey(item, column)}
                      onClick={() => this.handleClick(item, column.path)}
                    >
                      {/* {`item:${JSON.stringify(item)}`}
              {`column:${JSON.stringify(column)}`} */}
                      {this.renderCell(item, column)}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={createKey(item, column)}
                    // onClick={() => this.handleClick(item, column.path)}
                    >
                      <EditTd
                        student={item}
                        path={column.path}
                        itemId={item._id}
                        item={this.renderCell(item, column)}
                        onChanged={(id, path, value) =>
                          this.props.onChanged(id, path, value)
                        }
                        onDelete={onDelete}
                      />
                    </td>
                  );
                }
              }
              return null
            })}
          </tr>
        ))}
      </tbody>
    );
  }
}
class EditTd extends Component {
  state = { item: this.props.item, path: this.props.path };
  handleChange = ({ currentTarget: input }) => {
    const state = { ...this.state };
    state[input.name] = input.value;
    this.props.onChanged(this.props.itemId, this.props.path, input.value);
    this.setState(state);
  };
  render() {
    const { item, path } = this.state;
    if (path === undefined)
      return <button
        className="btn btn-danger btn-sm"
        onClick={() => this.props.onDelete(this.props.student)
        }
      >
        Delete
        </button>;
    else
      return (
        <input
          value={item}
          name="item"
          type="text"
          className="form-control mb-2"
          id="inlineFormInput"
          placeholder=""
          onChange={this.handleChange}
        />
      );
  }
}
export default TableBody;
