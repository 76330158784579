import React from "react";
import { useTheme } from "../context/ThemeContext";

const ThemeCustomizer = () => {
  const { theme, updateTheme } = useTheme();

  const handleColorChange = (e, key) => {
    updateTheme({ [key]: e.target.value }); // 선택한 색상 업데이트
  };

  return (
    <div style={{ padding: "1rem", backgroundColor: theme.background, color: theme.text }}>
      <h3>Customize Theme</h3>
      <div>
        <label>Primary Color: </label>
        <input
          type="color"
          value={theme.primary}
          onChange={(e) => handleColorChange(e, "primary")}
        />
      </div>
      <div>
        <label>Secondary Color: </label>
        <input
          type="color"
          value={theme.secondary}
          onChange={(e) => handleColorChange(e, "secondary")}
        />
      </div>
      <div>
        <label>Background Color: </label>
        <input
          type="color"
          value={theme.background}
          onChange={(e) => handleColorChange(e, "background")}
        />
      </div>
      <div>
        <label>Text Color: </label>
        <input
          type="color"
          value={theme.text}
          onChange={(e) => handleColorChange(e, "text")}
        />
      </div>
    </div>
  );
};

export default ThemeCustomizer;