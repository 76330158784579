import React, { useContext, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTheme } from "../context/ThemeContext"; // ThemeContext 가져오기
import { FaSun, FaMoon } from "react-icons/fa"; // 해와 달 아이콘 가져오기

const NavBar = ({ version, UserContext, onUserChange }) => {
  const user = useContext(UserContext);
  const { theme, toggleTheme } = useTheme(); // 테마와 테마 전환 함수 가져오기
  const navRef = useRef(null); // 메뉴를 참조하기 위한 useRef

  // 메뉴 닫기 함수
  const closeMenu = () => {
    if (navRef.current) {
      navRef.current.classList.remove("show"); // Bootstrap의 'show' 클래스 제거
    }
  };

  return (
    <nav
      className="navbar fixed-top navbar-expand-lg navbar-light bg-light"
      style={{
        backgroundColor: theme.background, // 테마 배경색
        color: theme.text, // 테마 텍스트 색상
      }}
    >
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <Link className="navbar-brand" to="/" style={{ color: theme.text }}>
        그랜드음악학원 v3.1
      </Link>

      {/* 다크 모드 전환 버튼 */}
      <button
        onClick={toggleTheme}
        style={{
          marginLeft: "auto", // 오른쪽 정렬
          backgroundColor: "transparent", // 투명 배경
          border: "none", // 테두리 제거
          cursor: "pointer",
          fontSize: "1.5rem", // 아이콘 크기
          color: theme.text, // 기본 아이콘 색상
        }}
      >
        {theme.background === "#212529" ? (
          <FaSun style={{ color: "#FFD700" }} /> // 태양 아이콘 색상: 노란색
        ) : (
          <FaMoon style={{ color: "#4B4B4B" }} /> // 달 아이콘 색상: 어두운 회색
        )}
      </button>

      <div
        className="collapse navbar-collapse"
        id="navbarNavAltMarkup"
        ref={navRef} // 메뉴를 참조
      >
        <div className="navbar-nav">
          {user && user.isTeacher && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/Chuls5"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                레슨
              </NavLink>
              </>
          )}
          {user && user.isTeacher && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/chulsRides"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                차/레슨
              </NavLink>
            </>
          )}
          {user && user.isPrincipal && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/studentsPhone"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                학생폰
              </NavLink>
            </>
          )}
          {user && user.isPrincipal && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/bus1"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                차
              </NavLink>
            </>
          )}
          {user && (user.isTeacher || user.isDriver || user.isCarTeacher) && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/rides"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                탑승명단
              </NavLink>
            </>
          )}
          {user && user.isTeacher && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/evals"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                평가
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/ontime"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                등원
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/docu"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                동영상 연주곡
              </NavLink>
            </>
          )}
          {user && user.isPrincipal && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/td"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                상황
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/studentAllCs"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                원생전체
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/ridesWeek1"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                등하원
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/etc"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                기타
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/dev"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                교재표
              </NavLink>
            </>
          )}
          {user && user.isAdmin && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/savePic1"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                사진등록동작
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/hbLetters"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                학부모편지
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/studentList"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                출석부-이름순
              </NavLink>
            </>
          )}
          {!user && (
            <>
              <NavLink
                className="nav-item nav-link"
                to="/login"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                Login
              </NavLink>
              <NavLink
                className="nav-item nav-link"
                to="/register"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                Register
              </NavLink>
            </>
          )}
          {user && (
            <>
              <span className="nav-link">{`(${user.name})`}</span>
              <NavLink
                className="nav-item nav-link"
                to="/logout"
                onClick={closeMenu} // 메뉴 닫기 함수 호출
              >
                Logout
              </NavLink>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
