import React, { useState, useEffect } from "react";
import {
  getMusics,
} from "../../../services/musicService";
import Table from "./table";
// 20200701 import "../../../my.css";

export const MusicLib = ({ user, fontSize, sortColumn, handleSort, onMusicSelect }) => {
  // onMusicSelect 로 선택한 곡 (item) 반환함. 
  const [musics, setMusics] = useState()
  useEffect(() => {
    async function downMusic() {
      const { data } = await getMusics();
      setMusics(data)
    }
    downMusic()
  }, []);
  const columns = [
    { path: "button", label: "" },
    { path: "melodizer", label: "작곡자" },
    { path: "musicTitle", label: "곡명" },
    { path: "musicBook", label: "교재명" },
  ];
  return (
    <>
      {user && user.isAdmin && (
        <div className="noPrint">
          <code>music/selectMusic.jsx</code>
        </div>
      )}
      {musics && (<Table
        data={musics}
        columns={columns}
        className="table table-bordered"
        sortColumn={sortColumn}
        fontSize={fontSize}
        onSort={handleSort}
        onMusicSelect={onMusicSelect}
      />)}
    </>
  );
}


