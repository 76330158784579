import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext"; // ThemeContext 가져오기
import { socketOnFuncs, getStudents, getPianos, getLessonsWithDate } from "../../services/index1";
import { getDayOfWeek } from "../../utils/func";
import { cutClass, initStat, evaluate, getWeekAgo, getToday, Filename, MakeButtons, Loading } from "../common/index1";
import { handleClick, ch, handleSetDay, menuModal, showPianoModal, handleOpenWin, Monitor, SwitchBoard } from "./index1";

function isStringInPjin(c, searchString) {
  if (!c || !c.pJin) return false; // c 또는 c.pJin이 없으면 false 반환
  return c.pJin.includes(searchString); // pJin에 searchString이 포함되어 있는지 확인
}

function addMinutesToISODate(isoDate, minutesToAdd) {
  const date = new Date(isoDate); // ISODate 문자열을 Date 객체로 변환
  date.setMinutes(date.getMinutes() + parseInt(minutesToAdd,10)); // 분 추가
  return date; // 다시 ISODate 형식으로 반환
}
function updateLessonAndOffTime(c) {
  let addedTime = "50"; // 기본 추가 시간

  // pJin에 "바이엘"이 포함되어 있고, attend가 조건에 맞는 경우 lessonMinutes를 40으로 설정
  if (
    isStringInPjin(c, "바이엘") && // pJin에 "바이엘" 포함 여부 확인
    (c.attend === null || c.attend === "" || c.attend.length === 4) // attend 조건 확인
  ) {
    // c.lessonMinutes = 40;
    addedTime = "40";
  }
  // pJin에 "체르니 100"이 포함되어 있고, attend가 조건에 맞는 경우 lessonMinutes를 45로 설정
  else if (
    isStringInPjin(c, "체르니 100") && // pJin에 "체르니 100" 포함 여부 확인
    (c.attend === null || c.attend === "" || c.attend.length === 4) // attend 조건 확인
  ) {
    // c.lessonMinutes = 45;
    addedTime = "45";
  } else {
    // c.lessonMinutes = 50; // 기본 수업 시간 설정
    addedTime = "50";
  }

  const onTime=c.onTime;
  let offTime
  offTime=addMinutesToISODate(onTime, addedTime); // offTime을 계산된 시간으로 설정

  c.offTime=offTime
  return c; // 수정된 c 객체 반환
}
function setOnTimeIfUndefined(c) {
  if (
    c.onTime === undefined ||
    c.onTime === null ||
    c.onTime === "" ||
    c.onTime === "2000-02-01T00:00:00.000Z" // 신입생의 경우
  ) {
    // c.onTime = getCurrentTimeAsHHMM(); // 현재 시간을 HH:MM 형식으로 설정
    c.onTime = new Date()
  }
  return c; // 수정된 c 객체 반환
}

const state = {
  chuls: [],
  day: [6, 0].includes(getDayOfWeek()) ? 1 : getDayOfWeek(), // 일 0, 월 1, 화 2, 수 3, 목 4, 금 5, 토 6, 토요일과 일요일은 월요일로 고친다.
  modalStudent: {},
  order: "name",
  pianos: [],
  showAjNum: false,
  showHak: false,
  showPic: false,
  showTel: false,
  showTime: false,
  win1open: false,
  win2open: false,
  counter: null,
  board1: null,
};
class Chuls extends Component {
  constructor(props) {
    super(props);
    this.state = state;
  }
  async setPianos() {
    let { data: pianos } = await getPianos();
    this.setState({ pianos });
  }
  async setChuls() {
    let { data: chuls } = await getStudents();
    let { data: times } = await getLessonsWithDate(getWeekAgo(getToday()));
    
    for (let c of chuls) {
      const cc=c
      c = setOnTimeIfUndefined(cc); // 분리된 함수 호출
      c = updateLessonAndOffTime(c); // 기존 로직 유지
    }


    for (let time of times) {
      const index = chuls.findIndex(chul => chul._id === time.studentId);
      if (chuls[index] !== undefined) {
        chuls[index].time = JSON.stringify(time.onTime).substring(12, 17);
      } 
    }

    this.setState({ chuls });

  }
  dataDown() {
    this.setPianos();
    this.setChuls();
  }
  async setShowPic() {
    const { location } = this.props;
    if (location !== undefined) {
      if (location.state !== undefined) {
      }
    }
  }
  async componentDidMount() {
    this.dataDown();
    this.setShowPic();
    let params = { room: "레슨", name: this.props.user.name };
    this.props.socket.emit("join", params, socketOnFuncs(this, this.props.socket));
  }

  render() {
    const { chuls, win1open, win2open, modalStudent, pianos } = this.state;
    const socket = this.props.socket;
    const { theme } = this.props; // HOC로 전달된 테마

    const menuParam = {
      win1open,
      cutClass,
      modalStudent,
      initStat,
      evaluate,
      history: this.props.history,
      thisScope: this,
      socket,
    };

    const pianoParam = {
      win2open,
      modalStudent,
      pianos,
      thisScope: this,
      socket,
    };

    const menuButtonParam = { history: this.props.history, thisScope: this, socket: this.props.socket };

    const buttons = MakeButtons({
      students: this.state.chuls,
      day: this.state.day,
      order: this.state.order,
      showTime: this.state.showTime,
      showHak: this.state.showHak,
    });

    const ban = {
      showPic: this.state.showPic,
      showAjNum: this.state.showAjNum,
      onOpenWin: (b) => handleOpenWin({ student: b, thisScope: this }),
      showTel: this.state.showTel,
      buttons: buttons,
      onClick: handleClick(this, socket, this.props.history),
    };

    const board = {
      students: this.state.chuls,
      day: this.state.day,
    };

    if (chuls.length === 0) {
      return <Loading></Loading>;
    } else {
      return (
        <div
          style={{
            backgroundColor: theme.background, // 테마 배경색
            color: theme.text, // 테마 텍스트 색상
          }}
        >
          {menuModal(menuParam)}
          {showPianoModal(pianoParam)}
          {Filename("chuls/chuls5.jsx")}
          <Monitor board={board} ban={ban} user={this.props.user} counter={this.state.counter} board1={this.state.board1} />
          <SwitchBoard scope={this} onSetDay={handleSetDay} ch={ch} param={menuButtonParam} />
        </div>
      );
    }
  }
}

// ThemeContext를 HOC로 전달
const withTheme = (Component) => (props) => {
  const { theme } = useTheme();
  return <Component {...props} theme={theme} />;
};

export default withRouter(withTheme(Chuls));
