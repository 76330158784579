import React from "react";
import { showName, showTime } from "../common/chulsFunc";
import Filename from '../common/filename';
import { gql, useQuery } from '@apollo/client';

const ALARM_BOARD = gql`
query AlarmBoard($day: String!){
  alarmBoard(day:$day){
    time
    place
    name
  }
}
`;

const Board = ({ board, board1 }) => {
  const day = "" + board.day
  const { loading, error, data } = useQuery(ALARM_BOARD, {
    variables: { day }, fetchPolicy: 'network-only'
  });
  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;
  if (board1 !== null && board1 !== undefined) {
    return (
      <>
        {Filename('board.jsx')}
        <div >
          {board1.map(b => (
            <button
              className="btn mr-1 mb-1 p-1 btn-outline-danger"
              key={b._id + b.time}
              disabled
              style={{ border: "3px solid red" }}
            >
              {showTime(b.time)}
              {b.place}
              {showName(b)}
            </button>
          ))}
        </div>
      </>
    );
  }
  return (
    <>
      {Filename('board.jsx')}
      <div style={{ lineHeight: "4.0rem" }}>
        {data.alarmBoard.map(b => (
          <button
            className="btn mr-1 mb-1 p-1 btn-outline-danger"
            key={b._id + b.time}
            disabled
            style={{ border: "3px solid red" }}
          >
            {showTime(b.time)}
            {b.place}
            {showName(b)}
          </button>
        ))}
      </div>
    </>
  );
}

export default Board;