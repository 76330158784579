export const getPassedDays = (date, dates) => {
  //ex date: '20190607',dates: array of days of months
  if (date === null) return 0;
  let days = 0;
  const month = getMonthNum(date);
  if (month === 1) return getDateNum(date);
  else {
    dates.map(function (item, index) {
      if (index < month - 1) days = days + item;
      return null
    });
    return days + getDateNum(date);
  }
};
export const dayDiff = (a, b) => {
  if (a === null) return null
  else if (b === null) return null
  else {
    const value111 = new Date(b.substr(0, 4) + '/' + b.substr(4, 2) + '/' + b.substr(6, 2)) -
      new Date(a.substr(0, 4) + '/' + a.substr(4, 2) + '/' + a.substr(6, 2))
    return value111 / (1000 * 60 * 60 * 24)
  }
}
export const getMonth = data => data.substring(4, 6); // ex. data='20190607'
export const getMonthNum = data => parseInt(getMonth(data)); // ex. data='20190607'
export const getDateNum = data => parseInt(getDate(data)); // ex. data='20190607'
export const getDate = data => data.substring(6, 8); // ex. data='20190607'

export const YYMMDD_Book = (date, book) =>
  date.substring(2, 4) +
  "/" +
  (date.substring(4, 5) === 0 ? date.substring(5, 6) : date.substring(4, 6)) +
  "/" +
  (date.substring(6, 7) === 0 ? date.substring(7, 8) : date.substring(6, 8)) +
  " " +
  book;
export const YYMM = date =>
  date.substring(2, 4) +
  (date.substring(4, 5) === 0 ? date.substring(5, 6) : date.substring(4, 6));
const getBookStartDate = (title, lessonsAll) => {
  //#region
  const reg = "(" + title + "\\d+)(권)?";
  const getBook = lesson => {
    if (lesson.theory === null) return null;
    let a = String(lesson.theory)
      .replace(" ", "")
      .match(reg);
    if (a) {
      return a[0] !== null ? { date: lesson.date, book: a[0] } : null;
    }
    return null;
  };
  let books = [];
  for (let l of lessonsAll) {
    const a = getBook(l);
    if (a !== null) books.push(a);
  }
  let bookRecords = [];
  for (let b of books) {
    if (bookRecords.length === 0) bookRecords.push(b);
    else if (bookRecords[bookRecords.length - 1].book !== b.book)
      bookRecords.push(b);
  }
  return bookRecords;
};
const getBookStartDate4 = (title, lessonsAll) => {
  //#region
  const reg = "(" + title + ")";
  const getBook = lesson => {
    if (lesson.theory === null) return null;
    let a = String(lesson.theory)
      .replace(" ", "")
      .match(reg);
    if (a) {
      return a[0] !== null ? { date: lesson.date, book: a[0] } : null;
    }
    return null;
  };
  let books = [];
  for (let l of lessonsAll) {
    const a = getBook(l);
    if (a !== null) books.push(a);
  }
  let bookRecords = [];
  for (let b of books) {
    if (bookRecords.length === 0) bookRecords.push(b);
    else if (bookRecords[bookRecords.length - 1].book !== b.book)
      bookRecords.push(b);
  }
  return bookRecords;
};
const getBookStartDate2 = (title, lessonsAll) => {
  //#region
  const reg = "(" + title + ")(\\d+)?(권)?";
  const getBook = lesson => {
    if (lesson.pJin === null) return null;
    let a = String(lesson.pJin)
      .replace(" ", "")
      .match(reg);
    if (a) {
      return a[0] !== null ? { date: lesson.date, book: a[0] } : null;
    }
    return null;
  };
  let books = [];
  for (let l of lessonsAll) {
    const a = getBook(l);
    if (a !== null) books.push(a);
  }
  let bookRecords = [];
  for (let b of books) {
    if (bookRecords.length === 0) bookRecords.push(b);
    else if (bookRecords[bookRecords.length - 1].book !== b.book)
      bookRecords.push(b);
  }
  return bookRecords;
};
const getBookStartDate3 = (title, lessonsAll) => {
  //#region
  const reg = title;
  const getBook = lesson => {
    if (lesson.pJin === null) return null;
    let a = String(lesson.pJin)
      .replace(" ", "")
      .match(reg);
    if (a) {
      return a[0] !== null ? { date: lesson.date, book: a[0] } : null;
    }
    return null;
  };
  let books = [];
  for (let l of lessonsAll) {
    const a = getBook(l);
    if (a !== null) books.push(a);
  }
  let bookRecords = [];
  for (let b of books) {
    if (bookRecords.length === 0) bookRecords.push(b);
    else if (bookRecords[bookRecords.length - 1].book !== b.book)
      bookRecords.push(b);
  }
  return bookRecords;
};
const getBookStartDate1 = lessonsAll => {
  //#region
  const book = [
    "바이엘1권",
    "바이엘2권",
    "바이엘3권",
    "바이엘4권",
    "체르니100",
    "체르니30",
    "체르니40",
    "체르니50"
  ];
  let books = [];
  book.map(title => {
    const reg = "(" + title + ")";
    const getBook = lesson => {
      if (lesson.pJin === null) return null;
      let a = String(lesson.pJin)
        .replace(" ", "")
        .match(reg);
      if (a) {
        return a[0] !== null ? { date: lesson.date, book: a[0] } : null;
      }
      return null;
    };
    for (let l of lessonsAll) {
      const a = getBook(l);
      if (a !== null) books.push(a);
    }
    return null
  });
  let bookRecords = [];
  for (let b of books) {
    if (bookRecords.length === 0) bookRecords.push(b);
    else if (bookRecords[bookRecords.length - 1].book !== b.book)
      bookRecords.push(b);
  }
  return bookRecords;
};

export const theoryBook1 = ["음악이론", "계이름공부", "초코렛문제은행", "평가문제집", "마무리음악이론", "음악유치원", "아이좋아", "악전교실"];
export const theoryBook2 = ["음악종합장", "음악놀이", "음악노트",];
export const pJinBook1 = ["반주완성"];
export const pJinBook2 = ["동요집", "소곡집", "소나티네", "하농", "명곡집", "K pop", "k-pop", "연주곡", "협주곡"];
export const mostBooks = (studentId, name, lessonsAll) => {
  const books = [];
  theoryBook1.map(title => {
    let lessons = lessonsAll.filter(l => l.studentId === studentId);
    let a = getBookStartDate(title, lessons);
    books.push({ title: title, books: a });
    return null
  });
  //==============================================================
  theoryBook2.map(title => {
    let lessons = lessonsAll.filter(l => l.studentId === studentId);
    let a = getBookStartDate4(title, lessons);
    books.push({ title: title, books: a });
    return null
  });
  //==============================================================
  pJinBook1.map(title => {
    let lessons = lessonsAll.filter(l => l.studentId === studentId);
    let a = getBookStartDate2(title, lessons);
    books.push({ title: title, books: a });
    return null
  });
  //==============================================================
  pJinBook2.map(title => {
    let lessons = lessonsAll.filter(l => l.studentId === studentId);
    let a = getBookStartDate3(title, lessons);
    books.push({ title: title, books: a });
    return null
  });
  //==============================================================
  let lessons = lessonsAll.filter(l => l.studentId === studentId);
  let a = getBookStartDate1(lessons);
  books.push({ title: "주교재", books: a });
  return {
    studentId: studentId,
    name: name,
    books: books
  };
}
export const book = ["음악이론", "계이름공부", "초코렛문제은행"];
export const allBooks = (studentId, name, lessonsAll) => {
  const books = [];
  book.map(title => {
    let lessons = lessonsAll.filter(l => l.studentId === studentId);
    let a = getBookStartDate(title, lessons);
    // if (title.includes('초코렛')) console.log('b', name, title)
    books.push({ title: title, books: a });
    return null
  });
  //==============================================================
  let lessons = lessonsAll.filter(l => l.studentId === studentId);
  let a = getBookStartDate1(lessons);
  books.push({ title: "주교재", books: a });
  return {
    studentId: studentId,
    name: name,
    books: books
  };
};
