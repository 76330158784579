import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export const themes = {
    light: {
        primary: '#007bff',
        secondary: '#6c757d',
        background: '#ffffff',
        text: '#212529',
        card: '#f8f9fa',
        border: '#dee2e6',
        hover: '#e9ecef',
        alert: {
            success: '#d4edda',
            warning: '#fff3cd',
            danger: '#f8d7da',
            info: '#d1ecf1'
        }
    },
    dark: {
        primary: '#0d6efd',
        secondary: '#adb5bd',
        background: '#212529',
        text: '#f8f9fa',
        card: '#343a40',
        border: '#495057',
        hover: '#495057',
        alert: {
            success: '#1e4620',
            warning: '#856404',
            danger: '#721c24',
            info: '#0c5460'
        }
    }
};

export const ThemeProvider = ({ children }) => {
    // 초기화 시 localStorage에서 테마를 불러옴
    const storedTheme = localStorage.getItem('theme') || 'light';
    const [theme, setTheme] = useState(themes[storedTheme]);

    const toggleTheme = () => {
        const newTheme = theme === themes.light ? 'dark' : 'light';
        setTheme(themes[newTheme]);
        localStorage.setItem('theme', newTheme); // 테마 상태를 localStorage에 저장
    };

    useEffect(() => {
        // localStorage에 저장된 테마를 적용
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(themes[savedTheme]);
        }
    }, []);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};