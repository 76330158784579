import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Filename from '../common/filename';
import { btnStyle1 } from "../common/setting";

const TEACHER = gql`
query Teacher{
  teacher {
    teacherId
    teacherName
    didTime {
      time
      action
      studentId
      studentName
    }
  }
}
`;

const hangul = (action) => {
  switch (action) {
    case 'waitLesson': return '레슨대기'
    case 'atSchool': return '학원도착'
    case 'goHome': return '귀가'
    case 'startLesson': return '레슨시작'
    case 'endLesson': return '레슨종료'
    default: return ''
  }
}

const TeacherDid = (props) => {
  const { loading, error, data } = useQuery(TEACHER);
  if (loading) return <p>읽는중...</p>;
  if (error) return <p>에러 :(</p>;
  return (
    <>
      {Filename('teacherDid.jsx')}
      <div className="container text-center">
        <div className='row'>
          <h4 className='col-6'>교 육 진 행 상 황</h4>
          {props.user && props.user.isTeacher && (
            < i className="fas fa-chalkboard-teacher fa-lg col-2" onClick={() => {
              props.history.push("/Chuls5");
            }} style={btnStyle1}
            ></i>
          )
          }
        </div>
        {data.teacher.map(t => {
          return (
            <div className="card m-2" style={{ "backgroundColor": "#96d5ff" }}>
              <div className="row text-center" style={{ fontSize: "12px" }}>
                <p className="col-4 m-2"> {t.teacherName}  </p>
              </div>
              {t.didTime.map(d => {
                return (
                  <div className="row text-center card-body m-2" style={{ fontSize: "12px", "backgroundColor": "#f7fffc", "border": "3px dashed red" }}>
                    <p className="col-4 m-2">{new Date(d.time * 1).toISOString().substr(11, 8)}</p>
                    <p className="col-4 m-2"> {hangul(d.action)}  </p>
                    <p className="col-4 m-2"> {d.studentName}  </p>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </>
  );
}
export default TeacherDid