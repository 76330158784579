import { Submit } from "../common/chulsFunc2";
import { isOn } from "../../utils/stat";
const { 등원, 학원도착, 레슨시작, 레슨마침, 하원, 결석 } = require("../../gma/Stats");

const handleClick = (thisScope, socket, history) => (student) => {
  // 클릭하면 상태가 변경됨. 도착아니면 -> 도착-> 등원-> 레슨시작 -> 레슨마침 -> 하원

  /*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
  // Date.prototype.timeNow = timeNow;

  if (!isOn(student, 결석)) {
    if (isOn(student, 레슨마침)) {
      Submit(
        student,
        하원,
        thisScope,
        socket
      );
    } else if (isOn(student, 레슨시작)) {
      history.push(`/eval/${student._id}`);
    } else if (isOn(student, 등원)) {
      Submit(
        student,
        레슨시작,
        thisScope,
        socket
      );
    } else if (isOn(student, 학원도착)) {
      // 서버에서 등원시각을 직접생성 기록한후 내려보내고
      // 그걸 받아서 레슨대기 학생 등원시각순 정렬하여 나타내기 때문에
      // 학원도착에서 클릭시 학생이름이 등원시각없이 맨앞으로 갔다가
      // 다시 등원시각을 표시하며 맨뒤로 나타나는 현상이 발생함.
      // 해결책은 여기서 일단 등원시각을 따로 만드는 방법이 있음.
      // 나중에 등원시각을 내려받아도 순서는 바뀌지 않게됨.
      // 그래서 그렇게 구현함.
      // student.onTime = new Date();
      // student.offTime = new Date();
      Submit(
        student,
        등원,
        thisScope,
        socket
      );
    } else if (!isOn(student, 학원도착)) {
      Submit(
        student,
        등원,
        thisScope,
        socket
      );
    }
  }
}

export default handleClick;
