export const weekDay = function () {
  const names = ["일", "월", "화", "수", "목", "금", "토"];
  return {
    name(number) { return names[number]; },
    number(name) { return names.indexOf(name); }
  };
}();

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
export function getDayOfWeek() {
  return new Date().getDay(); // 월 1, 화 2, 수 3, 목 4, 금 5, 토 6, 일 0
}
export function getDayOfWeekHangul() {
  return weekDay.name(getDayOfWeek())
}

export function dowHangul(dow) {
  return dow >= 1 && dow <= 5 ? weekDay.name(dow) : null;
}
export function replaceAt(string, index, replace) {
  return (
    string.substring(0, index) +
    replace +
    string.substring(index + 1, string.length)
  );
}
export const pastMinutes = date => {
  const now = new Date();
  now.setHours(now.getHours() ); // 9를 없앨수는 없나?

  const past = new Date(date);

  return Math.floor((now - past) / 60000);
};
export const showTime = date => {
  // const past = new Date(date);

  return (
    (String(date).substring(11, 12) === 0
      ? String(date).substring(12, 13)
      : String(date).substring(11, 13)) +
    ":" +
    String(date).substring(14, 16)
  );
};
