import http from "./httpService";

const apiEndpoint = "/books";

function bookUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getBooks() {
  return http.get(apiEndpoint);
}
export function saveBooksAsExcel() {
  return http.get(apiEndpoint + "/toexcel");
}

export function getBook(bookId) {
  return http.get(bookUrl(bookId));
}

export function saveBook(book) {
  if (book._id) {
    const body = { ...book };
    return http.put(bookUrl(book._id), body);
  }
  return http.post(apiEndpoint, book);
}
export function saveBook1(book) {
  return http.post(apiEndpoint + "/record", book);
}
export function readExcelAsBooks(filename) {
  // if (book._id) {
  //   const body = { ...book };
  //   return http.put(bookUrl(book._id), body);
  // }
  // return http.post(apiEndpoint, book);
}
export function deleteBook(bookId) {
  return http.delete(bookUrl(bookId));
}
