import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios for API calls

const StudentList = () => {
  const [rows, setRows] = useState(() => {
    const savedRows = localStorage.getItem("studentListRows");
    return savedRows
      ? JSON.parse(savedRows)
      : [
          {
            instrument: "피아노",
            paymentDate: "2025-04-01",
            educationFee: "500,000원",
            name: "홍길동",
            grade: "3학년",
            class: "A반",
            id: "12345",
            sessions: "10회",
            attendanceDay: "월요일",
            dob: "2015-05-01",
            apartment: "그린아파트",
            depositor: "홍길동",
          },
        ];
  });

  const [newRow, setNewRow] = useState({
    instrument: "",
    paymentDate: "",
    educationFee: "",
    name: "",
    grade: "",
    class: "",
    id: "",
    sessions: "",
    attendanceDay: "",
    dob: "",
    apartment: "",
    depositor: "",
  });

  useEffect(() => {
    localStorage.setItem("studentListRows", JSON.stringify(rows));
  }, [rows]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRow({ ...newRow, [name]: value });
  };

  const handleCellChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([...rows, newRow]);
    setNewRow({
      instrument: "",
      paymentDate: "",
      educationFee: "",
      name: "",
      grade: "",
      class: "",
      id: "",
      sessions: "",
      attendanceDay: "",
      dob: "",
      apartment: "",
      depositor: "",
    });
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const rowsFromClipboard = clipboardData
      .split("\n")
      .map((row) => row.split("\t"))
      .filter((row) => row.length > 1);

    const updatedRows = rowsFromClipboard.map((cols) => {
      const keys = Object.keys(newRow);
      const row = {};
      keys.forEach((key, index) => {
        row[key] = cols[index] || "";
      });
      return row;
    });

    setRows([...rows, ...updatedRows]);
  };

  const deleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const deleteAllRows = () => {
    if (window.confirm("정말로 모든 데이터를 삭제하시겠습니까?")) {
      setRows([]);
      localStorage.removeItem("studentListRows");
    }
  };

  const saveToDatabase = async () => {
    try {
      const response = await axios.post("/studentTable", { students: rows });
      if (response.status === 200) {
        alert("데이터가 성공적으로 저장되었습니다!");
      } else {
        alert("데이터 저장에 실패했습니다.");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("데이터 저장 중 오류가 발생했습니다.");
    }
  };

  return (
    <div>
      <h1 style={{ marginTop: "20px" }}>2025년 4월 출석부 (이름순)</h1>
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
        onPaste={handlePaste}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>악기</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>결제일</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>교육비</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>이름</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>학년</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>반</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>ID</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>회</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>출석요일</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>생년월일</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>아파트</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>입금자</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>삭제</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {Object.keys(row).map((key) => (
                <td key={key} style={{ border: "1px solid black", padding: "8px" }}>
                  <input
                    name={key}
                    value={row[key]}
                    onChange={(e) => handleCellChange(e, index)}
                    style={{ width: "100%" }}
                  />
                </td>
              ))}
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <button onClick={() => deleteRow(index)} style={{ width: "100%" }}>
                  삭제
                </button>
              </td>
            </tr>
          ))}
          <tr>
            {Object.keys(newRow).map((key) => (
              <td key={key} style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  name={key}
                  value={newRow[key]}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </td>
            ))}
            <td style={{ border: "1px solid black", padding: "8px" }}></td>
          </tr>
        </tbody>
      </table>
      <button onClick={addRow} style={{ marginTop: "10px", marginRight: "10px" }}>추가</button>
      <button onClick={deleteAllRows} style={{ marginTop: "10px", marginRight: "10px", backgroundColor: "red", color: "white" }}>
        모두 삭제
      </button>
      <button onClick={saveToDatabase} style={{ marginTop: "10px", backgroundColor: "green", color: "white" }}>
        저장
      </button>
    </div>
  );
};

export default StudentList;