import React from 'react'
import axios from 'axios'
import { toast } from "react-toastify";

const initialState = {
  bio: '',
  selectedFile: {},
  newFile: '',
  error: ''
}

class SavePic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    }
  }
  selectedFile(e) {
    e.preventDefault();
    let selectedFile = e.target.files[0]
    this.setState({
      selectedFile
    });
  }

  fileUPload(e, selectedFile) {

    e.preventDefault();
    const data = new FormData();
    const file = this.state.selectedFile;
    data.append('selectedFile', file);
    const url = process.env.REACT_APP_API_URL
    const url1 = url.substring(0, url.indexOf('/api'))
    axios.post(`${url1}/upload`, data).then(({ data: { newFileName } }) => {
      this.setState({
        newFile: newFileName
      })
      selectedFile(newFileName).then(async () => {

        // this.props.history.push('/edit-profile');
        toast('We have updated your profile image!', 'Saved!');

      }).catch(() => {
      });
    }).catch(() => {
    });
  }
  render() {
    const setProfileIMG = () => { }
    const name = this.props.name.props.value
    //return '사진'
    return (
      <form onSubmit={event => this.fileUPload(event, setProfileIMG)}>
        <div >{this.props.name}</div>
        <div className="grid">
          <div className="column column_6_12 image">
            <img
              src={`/assets/face/${name}.png`}
              alt=''
            />
            {/* {!this.props.session.getCurrentUser.profileImage &&
              <img src={`assets/graphics/abstract_patterns/texture.jpg`} />
            }
            {this.props.session.getCurrentUser.profileImage &&
              <img src={`user-uploads/${this.props.session.getCurrentUser.profileImage}`} />
            } */}
          </div>
          <div className="column column_6_12">
            <h3>Profile image: </h3>
            <div className="file_input">
              <input type="file" accept=".jpg, .png" name="profilePic" onChange={e => this.selectedFile(e)} />
            </div>
            <div className="form_buttons">
              <button type="submit" className="btn">
                Upload</button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
export default SavePic