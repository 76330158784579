import React from "react";
import SaveB from "../common/saveB";
import { handleDelAll, handleRecall, handleSaveMaster } from "./handler";

export const menuButtons = ({ user, history, chuls, dataDown, toast, socket }) => {
  //debugger;
  return (
    <div
      className="menu"
    >
      {user && user.isPrincipal && (
        <>
          <button
            className="btn btn-info mr-2 mb-1"
            style={{
              color: "rgb(0,0,0)",
              backgroundColor: "rgba(255, 237, 199, .3)"
            }}
            onClick={() => {
              history.push("/bus1");
            }}
          >
            차
          </button>
        </>
      )}
      {user && user.isAdmin && (
        <>
        <button
        className="btn btn-info mr-2 mb-1"
        style={{
          color: "rgb(0,0,0)",
          backgroundColor: "rgba(255, 237, 199, .3)"
        }}
        onClick={() => {
          history.push("/ridesWeek1");
        }}
      >
        원본
      </button>
        <SaveB
          toast={toast}
          user={user}
          chuls={chuls}
          dataDown={dataDown}
          onSave={({ title, chuls, user, toast }) =>
            handleSaveMaster({ title, chuls, user, socket, toast })
          }
          onRecall={({ title, chuls, user, dataDown, toast }) =>
            handleRecall({ title, chuls, user, dataDown, socket })
          }
          onDelAll={({ fields, chuls, user, toast }) =>
            handleDelAll({ fields, chuls, user, socket, toast })
          }
        >
          원본저장
        </SaveB>
        </>
      )}
      {user && user.isTeacher && (
        <i
          className="fas fa-chalkboard-teacher fa-lg"
          onClick={() => {
            history.push("/Chuls5");
          }}
          style={{
            marginRight: 13,
            backgroundColor: "rgba(255, 237, 199, .3)",
            padding: "10px",
            border: "1px solid rgba(23,162,184,1)",
            borderRadius: "0.25rem"
          }}
        ></i>
      )}
    </div>
  );
};
