import React, { Component } from "react";
import { toast } from "react-toastify";
import { useTheme } from "../../context/ThemeContext"; // ThemeContext 가져오기
import Loading from "../common/loading";
import * as modal from "./selectModal";
import { menuButtons } from "./menuButtons";
import { dayButtons } from "./day";
import { handleSetDay } from "./handler";
import { RidesChart } from "./ridesChart";
import { FloatButtons } from "./floatBtns";
import * as rh from "./ridesHandlers";
import { onFuncs } from "./onFunc";
import fileName from "../common/func2";
import auth from "../../services/authService";
import socketOnFuncs from "../../services/socketEvents";
import { getStudents } from "../../services/busService";
import { getDayOfWeek } from "../../utils/func";
import { HandlerProvider } from "../new/handler";

const initialState = {
  users: [],
  messages: [],
  newMsg: "",
  fetchingLocation: false,
};

class Rides extends Component {
  state = {
    chuls: [],
    chulsOrig: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    sortColumn: { path: "이름", order: "asc" },
    dow: 0, // 일 0, 월 1, 화 2, 수 3, 목 4, 금 5, 토 6
    todaysDow: 1,
    showNameOnly: false,
    showTel: false,
    showPic: false,
    showAjNum: false,
    win1open: false,
    win2open: false,
    win1param: {},
    win2param: {},
    green: true
  };

  constructor(props) {
    super(props);
    this.state.dow = [6, 0].includes(getDayOfWeek()) ? 1 : getDayOfWeek(); // 토, 일일 땐 월요일을 보여준다
    this.state.todaysDow = this.state.dow; // 오늘의 요일을 기억해둔다
  }

  async componentDidMount() {
    this.dataDown();
    let params = {
      name: "",
      room: "",
    };

    params = { room: "탑승정보", name: this.user.name };
    this.props.socket.emit("join", params, socketOnFuncs(this, this.props.socket));
  }

  componentWillUnmount() {
    const param = {
      room: this.props.match.params.room,
    };
    this.props.socket.emit("leave", param);
    this.setState({ ...initialState });
  }

  user = auth.getCurrentUser();

  async dataDown() {
    let { data: chuls } = await getStudents();
    this.setState({ chuls, chulsOrig: JSON.parse(JSON.stringify(chuls)) });
  }

  doSubmit = async (student) => {
    delete student.id;
    delete student.onTime;
    let params = {
      user: this.user._id,
      name: student.name,
      _id: student._id,
      status: student.status,
      from: "rides",
    };
    this.props.socket.emit("status", params, function (err) {});
  };

  submitable = (student) => {
    let student1 = { ...student };
    [
      "time",
      "color",
      "new_time",
      "onTime",
      "myBadge",
      "myButton",
      "등원시각",
      "상태",
    ].map((f) => delete student1[f]); // fields not to submit
    return student1;
  };

  render() {
    const {
      chuls,
      chulsOrig,
      showTel,
      showPic,
      showAjNum,
      showNameOnly,
      win1open,
      win2open,
      win1param,
      win2params,
      dow,
      todaysDow,
    } = this.state;
    const socket = this.props.socket;

    // ThemeContext에서 테마 가져오기
    const { theme } = this.props;
    
    if (chuls.length === 0) {
      return <Loading></Loading>;
    } else
      return (
        <div
          style={{
            backgroundColor: theme.background,
            color: theme.text,
            textAlign: "left", // 왼쪽 정렬 추가
          }}
        >
          <HandlerProvider
            value={onFuncs({
              chuls: chuls,
              setState: (a) => this.setState(a),
              doSubmit: (a) => this.doSubmit(a),
              submitable: (a) => this.submitable(a),
              socket,
            })}
          >
            <FloatButtons
              f={{
                showTelFunc: () => this.setState({ showTel: !showTel }),
                showPicFunc: () => this.setState({ showPic: !showPic }),
                showAjNumFunc: () => this.setState({ showAjNum: !showAjNum }),
                showNameOnlyFunc: () =>
                  this.setState({ showNameOnly: !showNameOnly }),
                dataDownFunc: () => this.dataDown(),
                NameOnly: showNameOnly,
              }}
            />
            {modal.selectNameModal({
              win2open,
              win1param,
              win2params,
              rh,
              chuls,
              setState: (a) => this.setState(a),
              socket,
            })}
            {modal.selectLastNameModal({
              win1open,
              win1param,
              rh,
              chuls,
              setState: (a) => this.setState(a),
              socket,
            })}
            {fileName("rides.jsx", this.user)}

            {/* dayButtons */}
            <div > 
              {dayButtons({
                user: this.user,
                dow,
                setDay: handleSetDay,
                thisScope: this,
              })}
            </div>

            {/* menuButtons */}
            <div > 
              {menuButtons({
                user: this.user,
                history: this.props.history,
                chuls: chuls,
                dataDown: (a) => this.dataDown(a),
                toast: (a) => toast(a),
                socket,
              })}
            </div>

            <div>
              <RidesChart
                chuls={chuls}
                chulsOrig={chulsOrig}
                dow={dow}
                todaysDow={todaysDow}
                showNameOnly={showNameOnly}
                showPic={showPic}
                showTel={showTel}
                showAjNum={showAjNum}
                green={this.state.green} // green 상태 전달
              />
            </div>
          </HandlerProvider>
        </div>
      );
  }
}

// ThemeContext를 HOC로 전달
const withTheme = (Component) => (props) => {
  const { theme } = useTheme();
  return <Component {...props} theme={theme} />;
};

export default withTheme(Rides);
