import React, { useRef, useState, useEffect } from 'react';

const CameraComponent = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [studentName, setStudentName] = useState('');
  const [ajNum, setAjNum] = useState('');

  useEffect(() => {
    const getVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
        videoRef.current.srcObject = stream;
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    getVideo();
  }, []);

  const capture = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const image = canvasRef.current.toDataURL('image/jpeg');
    setPhoto(image);
  };

  const upload = async () => {
    // if (!photo || !studentName || !ajNum) {
    if (!photo || !studentName ) {
      alert('Please enter student name ... and capture a photo.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', dataURItoBlob(photo), encodeURIComponent(studentName)+'.jpg'); // Convert Base64 to Blob

      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: 'POST',
        body: formData, // Use FormData for multipart/form-data
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert('Photo uploaded successfully!');
      setStudentName('');
      setAjNum('');
      setPhoto(null);
    } catch (error) {
      alert('Error uploading photo.');
    }
  };

  // Helper function to convert Base64 to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <div>
      <h1>Student Photo Upload</h1>
      <label htmlFor="studentName">Student Name:</label>
      <input
        type="text"
        id="studentName"
        value={studentName}
        onChange={(e) => setStudentName(e.target.value)}
        required
      />
      <br />
      <label htmlFor="ajNum">Identification Number (ajNum):</label>
      <input
        type="text"
        id="ajNum"
        value={ajNum}
        onChange={(e) => setAjNum(e.target.value)}
        required
      />
      <br />
      <video ref={videoRef} width={320} height={240} autoPlay />
      <button onClick={capture}>Capture</button>
      <canvas ref={canvasRef} width={320} height={240} style={{ display: 'none' }} />
      {photo && (
        <>
          <img src={photo} alt="Captured" />
          <button onClick={upload}>Upload</button>
        </>
      )}
    </div>
  );
};

export default CameraComponent;
